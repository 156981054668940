import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostList from '../components/PostList';

import { rhythm } from '../utils/typography';

const Tags = ({ pageContext, data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    const { social, author } = data.site.siteMetadata;
    const ogImage = data.imageSharp.fluid.src;
    const { tag } = pageContext;

    return (
        <Layout location={location} title={siteTitle} social={social} author={author}>
            <SEO title={siteTitle} description="linda 創作空間" image={ogImage} />
            <div css={css(tagWordStyle)}>
                <p
                    css={css({
                        fontSize: rhythm(3 / 4),
                        margin: `0 ${rhythm(1 / 3)}`,
                    })}
                >
                    Tagged
                </p>
                <h1 css={css({ margin: `0 ${rhythm(1 / 3)}` })}>{tag}</h1>
            </div>
            <div css={css(containerStyle)}>
                <PostList posts={posts} />
            </div>
            <div css={css(authorStyle)}>
                <Bio />
            </div>
        </Layout>
    );
};

Tags.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            slug: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
};

export default Tags;

export const pageQuery = graphql`
    query($tag: String) {
        site {
            siteMetadata {
                title
                author {
                    name
                    summary
                }
                social {
                    facebook
                    twitter
                    instagram
                }
            }
        }
        allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        tags
                        cover {
                            childImageSharp {
                                fixed(width: 400, height: 300) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
        imageSharp(fluid: { src: { regex: "/ogImage.jpg/" } }) {
            fluid {
                src
            }
        }
    }
`;

const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
};

const authorStyle = {
    marginTop: rhythm(2),
};

const tagWordStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: rhythm(1),
    marginBottom: rhythm(1),
};
